<script>
import { mapGetters } from 'vuex';
import * as funnelGetters from '@/store/funnel/getters';

export default {
  name: 'EzfFunnelUrl',
  computed: {
    ...mapGetters('funnel', {
      url: funnelGetters.FUNNEL_URL,
    }),
  },
  methods: {
    onCopy() {
      this.$notify({
        data: {
          type: 'success',
          content: this.$t('funnels.notifications.urlCopy.success'),
        },
      });
    },
    onError() {
      this.$notify({
        data: {
          type: 'error',
          content: this.$t('funnels.notifications.urlCopy.error'),
        },
      });
    },
  },
};
</script>

<template>
  <ez-button-group>
    <ez-input
      :value="url"
      size="small"
      readonly
      data-test="url"
    />
    <ez-button
      v-clipboard:copy="url"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
      icon="copy"
      size="small"
      type="primary"
      :title="$t('funnels.copyToClipboard')"
      data-test="copy"
    ></ez-button>
    <a :href="url" target="_blank">
      <ez-button
        icon="external-link"
        size="small"
        type="primary"
        :title="$t('funnels.openInNewTab')"
        data-test="open-new-tab"
      ></ez-button>
    </a>
  </ez-button-group>
</template>
