<script>
import { mapGetters } from 'vuex';
import {
  URL_IMPRESSUM,
  URL_TERMS,
  URL_CANCELLATION,
  getFaqLink,
} from '@dmant/ez-env-common';
import { mapLocales, DE } from '@/enums/locales';

export default {
  name: 'EzfPageFooter',
  data() {
    return {
      copyright: this.$t('footer.copyright'),
      socialLinks: [
        { name: 'facebook', href: 'https://go.ezfunnels.com/facebook' },
        { name: 'instagram', href: 'https://go.ezfunnels.com/instagram' },
        { name: 'youtube', href: 'https://go.ezfunnels.com/youtube' },
        // { name: <String>[, href: <String> ] }
        // names: facebook, instagram, twitter, google-plus, linkedin, youtube
      ],
    };
  },
  computed: {
    ...mapGetters({ locale: 'mainUserLocale' }),
    navLinks() {
      return [
        {
          name: 'imprint',
          text: this.$t('footer.imprint'),
          href: URL_IMPRESSUM,
        },
        {
          name: 'terms',
          text: this.$t('footer.terms'),
          href: URL_TERMS,
        },
        {
          name: 'cancellation',
          text: this.$t('footer.cancellation'),
          href: URL_CANCELLATION,
        },
        {
          name: 'faq',
          text: this.$t('footer.faq'),
          href: getFaqLink(mapLocales[this.locale] || DE),
        },
        // { name: <String>[, href: <String>, text: <String>] }
        // names: imprint, terms, cancellation, faq
      ];
    },
  },
};

</script>

<template>
  <ez-footer
    :social-links="socialLinks"
    :copyright="copyright"
    :nav-links="navLinks"
    service-name="funnels"
  >
  </ez-footer>
</template>
