import i18n from '../i18n';

export default function localezedProperyValue(attributes, property = 'name', locale = null) {
  // read already localized property - without lang postfix
  if (attributes[property]) {
    return attributes[property];
  }

  let [lang] = (locale || i18n.locale).match(/[a-z]+/);
  if (attributes[`${property}-${lang}`]) {
    return attributes[`${property}-${lang}`];
  }
  if (i18n.fallbackLocale) {
    [lang] = i18n.fallbackLocale.match(/[a-z]+/);
  } else {
    lang = 'en';
  }
  if (attributes[`${property}-${lang}`]) {
    return attributes[`${property}-${lang}`];
  }
  return '';
}
