<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import ezFunnelUrl from '@/components/funnel-url.vue';
import templateName from '@/filters/template-name';
import showCloneFunnelDialog from '@/components/dialogs/cloneFunnel';
import confirmStatsReset from '@/components/dialogs/confirmStatsReset';

import * as headerGetters from '@/store/header/getters';
import * as funnelActions from '@/store/funnel/actions';
import * as funnelMutations from '@/store/funnel/mutations';
import { logout, goToSsoPage } from '@/services/apiUser';
import EzfGroupCreateButton from '../funnels/group-create-button.vue';

export default {
  name: 'EzfPageHeader',
  components: { ezFunnelUrl, EzfGroupCreateButton },
  props: {},
  data() {
    return {
      navLinks: [],
      createDropdownOpened: false,
    };
  },
  created() {
    // console.log(this.$route);
    this.initNavLinks();
  },
  watch: {
    ACTIVE_NAVLINK_ID() {
      this.initNavLinks();
    },
  },
  computed: {
    ...mapState(['currentUserId']),
    ...mapState('funnel', ['funnel']),
    ...mapGetters({
      availableUsers: 'availableUsersPrettyFormat',
    }),
    ...mapState('header', ['navtabLinks', 'activeNavTabLink']),
    ...mapGetters('header', [
      headerGetters.NAV_LINKS,
      headerGetters.LIMITS,
      headerGetters.TARIFF,
      headerGetters.TITLE,
      headerGetters.ACTIVE_NAVLINK_ID,
    ]),
    navTabs() {
      return this.navtabLinks.map(({ name, ...v }) => ({
        ...v,
        active: v.id === this.activeNavTabLink,
        name: this.$t(name),
      }));
    },
    isShowCreateFunnel() {
      return this.$can('create', 'Funnels') && this.$route.matched.some((v) => ['dashboard', 'funnels'].includes(v.name));
    },
    isShowCreateGroup() {
      return this.$route.matched.some((v) => v.name === 'funnelsGroups');
    },
    isShowFunnelControls() {
      return this.$route.matched.some((v) => v.path === '/funnel/:funnel_id') && this.funnel && !this.funnel.archive;
    },
    isFunnelOverviewPage() {
      return this.$route.matched.some((v) => v.path === '/funnel/:funnel_id');
    },
    isFunnelListPage() {
      return this.$route.matched.some((v) => v.path === '/funnels');
    },
    isShowNavTabs() {
      return (this.isFunnelOverviewPage && this.funnel && !this.funnel.archive)
        || this.isFunnelListPage;
    },
    limits() {
      return Object.keys(this[headerGetters.LIMITS]).map((key) => ({
        name: this.$t(`limits.${key}`),
        value: this[headerGetters.LIMITS][key] || 0,
      }));
    },
    isShowLimits() {
      return this.limits.length > 0;
    },
    tariff() {
      const { current } = this[headerGetters.TARIFF];
      return current ? templateName(current) : ' ';
    },
  },
  methods: {
    ...mapActions(['CHANGE_USER']),
    ...mapMutations('funnel', [funnelMutations.CHANGE_ATTRIBUTE]),
    ...mapActions('funnel', [funnelActions.SAVE_FUNNEL_ATTRS, funnelActions.RESET_STATS]),
    toCreateFunnel() {
      this.$router.push({ name: 'wizard-goal' });
    },
    toCreateGroup() {
      window.EventBus.$emit('create-group');
    },
    onUserSelected(user) {
      this.CHANGE_USER(user.id);
    },
    async logout() {
      await logout();
      goToSsoPage('');
    },
    onClickTabs(tab) {
      this.$router.push(tab.route).catch(() => {});
    },
    onLogoClick() {
      this.$router.push('/').catch(() => {});
    },
    async onCloneFunnel() {
      const result = await showCloneFunnelDialog(this.funnel);
      if (result) {
        this.$router.push({ name: 'funnels' });
      }
    },
    async onArchiveFunnel() {
      this[funnelMutations.CHANGE_ATTRIBUTE]({
        attr: 'archive',
        value: true,
      });
      await this[funnelActions.SAVE_FUNNEL_ATTRS](['archive']);
      this.$router.push({ name: 'funnels' });
    },
    initNavLinks() {
      this.navLinks = (this.NAV_LINKS || []).map((item) => ({
        ...item,
        active: item.id === this.ACTIVE_NAVLINK_ID,
      }));
    },
    createDropdownInput() {
      this.createDropdownOpened = !this.createDropdownOpened;
    },
    async confirmResetStatistics() {
      await confirmStatsReset(this[funnelActions.RESET_STATS]);
    },
  },
};
</script>

<template>
  <ez-header
    service-name="funnels"
    @logoClick="onLogoClick"
  >
    <template slot="title">
      <ez-link
        v-for="(item, index) in TITLE"
        :key="index"
        :active="item.active"
        :href="item.href"
        :beRouterLink="true"
      >
        <span v-if="item.translated" v-text="item.name"></span>
        <span v-else v-t="item.name"></span>
      </ez-link>
    </template>

    <template slot="controls" v-if="isShowCreateFunnel">
      <ez-button
        type="primary"
        icon="plus"
        data-test="funnels-create-button"
        @click="$router.push({ name: 'wizard-goal' })"
      >
        {{ $t("funnels.createButton") }}
      </ez-button>
    </template>

    <template slot="controls" v-if="isShowCreateGroup">
      <ezf-group-create-button />
    </template>

    <template slot="controls" v-else-if="isShowFunnelControls">
      <ez-dropdown direction="right">
        <ez-button icon="ellipsis-h"></ez-button>
        <ez-dropdown-menu slot="menu">
          <!-- <ez-dropdown-item>
            {{ $t("funnel.….trackingManager") }}
          </ez-dropdown-item> -->
          <ez-dropdown-item @click="onCloneFunnel">
            {{ $t("funnel.….clone") }}
          </ez-dropdown-item>
          <ez-dropdown-item @click="onArchiveFunnel">
            {{ $t("funnel.….archive") }}
          </ez-dropdown-item>
          <ez-dropdown-item @click="confirmResetStatistics">
            {{ $t("funnel.….resetStatistics") }}
          </ez-dropdown-item>
        </ez-dropdown-menu>
      </ez-dropdown>
    </template>

    <template slot="advanced" v-if="isShowFunnelControls">
      <ez-row>
        <ez-col :size="{ sm: 12 }">
          <ez-funnel-url />
        </ez-col>
      </ez-row>
    </template>

    <template slot="tabs" v-if="isShowNavTabs">
      <ez-tabs :options="navTabs" @click="onClickTabs" />
    </template>

    <!-- Slot navigation -->
    <template slot="nav">
      <ez-link
        v-for="(item, index) in navLinks"
        :key="index"
        :active="item.active"
        :icon="item.icon"
        :href="item.route"
        :target="item.target || ''"
        :beRouterLink="item.isRouterLink"
        :data-test="item.testAttr"
      >
        <span v-t="item.name"></span>
      </ez-link>
    </template>

    <template slot="user">
      <ez-dropdown-user
        :selected="currentUserId"
        :options="availableUsers"
        :showSelectedInList="false"
        @user-selected="onUserSelected"
        @logout="logout"
        data-test="dropdown-user-button"
      ></ez-dropdown-user>
    </template>
  </ez-header>
</template>

<style lang="scss" scoped>
.uniq-button-create {
  transition: box-shadow 0.2s, background-color 0.2s, color 0.2s !important;

  &.is-active,
  &:focus {
    box-shadow: inset 0 0 0 2px #357dcd !important;
    color: $color-primary !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>
